import React, { Component } from 'react'
import Img from 'gatsby-image';
import styled from 'styled-components'

const StyledAdImage = styled(Img)`
  max-width: 800px;
`

export default class AdsImage extends Component {
  render() {
    return <StyledAdImage fluid={this.props.data.fluid} />
  }
}
