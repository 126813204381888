import React, {Component} from 'react'
import styled from 'styled-components'
import {Container, Row, Col, Modal} from 'react-bootstrap';
import pin from '../../../static/utils/pin-in-the-map.svg';
import whitePin from '../../../static/utils/pin-in-the-map-white.svg';
import carrot from '../../../static/utils/carrot.svg';
import CTAButton from '../utils/CTAButton'
import ScrollToTop from '../utils/ScrollToTop'
import AdsImage from './AdsImage'

const ExternalWrapper = styled.div`
  @media (min-width: 768px) {
    margin-bottom: 120px;
  }
`

const LocationMasthead = styled(Row)`
  margin: 50px 0 !important;
  height: 72px;
  width: 100%;
  background-color: ${props => props.theme.colors.base};
  @media (max-width: 768px) {
    margin: -5px 0 0 0 !important;
    height: 160px;
  }
`
const LocationTitle = styled.span`
  width: 1040px;
  margin: auto auto;
  padding: 0;
  h2 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.white};
    font-size: 35px;
    margin: 0;
  }
  @media (min-width: 1200px) {
    width: 1100px;
  }
  @media (max-width: 1024px) {
    h2 {
      margin-left: 1.5em;
    }
  }
  @media (max-width: 768px) {
    display: inline-flex;
    width: 82%;
    margin: auto auto auto 7%;
    h2 {
      margin-left: 0;
      font-size: 27px;
      width: fit-content;
    }
  }
`
const MobileTabForSelectYourStore = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 768px) {
    position: fixed;
    z-index: 100;
    background-color: ${props => props.theme.colors.base};
    height: 153px;
    width: 44px;
    opacity: .8;
    overflow: hidden;
    div {
      color: ${props => props.theme.colors.white};
      position: inherit;
      transform: rotate(-90deg);
      top: 310px;
      left: -40px;
      font-family: montserrat, sans-serif;
      font-size: 14px;
      font-weight: 700 !important;
    }
  }
`

const AdsWrapper = styled(Col)`
  display: flex;
  flex-flow: column;
  max-width: 800px;
`

const ContentWrapper = styled(Row)``

const LocationSidebar = styled(Col)`
  @media (max-width: 768px) {
    display: none;
    &.show-location-sidebar-mobile {
      display: block;
    }
  }
`

const LocationHeadline = styled.div`
  width: 478px; 
  height: 50px; 
  color: #333333; 
  font-size: 25px; 
  text-align: left; 
  line-height: 1px; 
  padding: 0;
  margin-bottom: 50px;
  border-bottom: 1px solid ${props => props.theme.colors.base};
  h4 {
    color: ${props => props.theme.colors.base};
    font-size: 25px;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 475px) {
    margin-top: 25px;
  }
`
const LocationPin = styled(Col)`
  padding-left: 0 !important;
  img {
    padding: 5px;
    margin: auto;
    max-height: 40px;
    max-width: 40px;
  }
  float: left;
  @media (max-width: 768px) {
    width: fit-content !important;
  }
`
const LocationPinMobile = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    img {
      padding: 5px;
      margin: auto;
      max-height: 40px;
      max-width: 40px;
    }
    margin-top: auto;
    float: right;
  }
`

const Location = styled.div`
  margin-bottom: 50px;
  h4 {
    color: ${props => props.theme.colors.base};
    font-size: 25px;
    margin-bottom: 40px;
  }
  @media (max-width: 475px) {
    button {
      height: auto !important;
    }
  }
`

// Modal Styles
const StyledModal = styled(Modal)``
const StyledModalBody = styled(Modal.Body)`
display: flex;
float: left;
flex-flow: column;
justify-content: flex-end;
@media screen and (min-width: 991px) {
  flex-flow: row;
}
`

const ModalLink = styled.p`
  @media only screen and (max-width: 768px) {
    font-size: 1.375rem !important;
    font-family: montserrat, sans-serif !important;
    font-weight: 700 !important;
    color: ${props => props.theme.colors.white};
    &:hover {
      color: ${props => props.theme.colors.navHighlight};
    }
    text-transform: capitalize;
  }
  color: ${props => props.theme.colors.base};
  text-decoration: none;
  font-weight: 700 !important;
  display: block;
  padding: .5rem 1rem;
  transition: all 0.2s;
  &:hover {
    color: ${props => props.theme.colors.navHighlight};
  }
`
// Modal Styles End

export default class AdsPage extends Component {
  constructor(props){
    super(props)
    this.state = {
      currentStore: null,
      currentStoreAds: null,
      stores: this.props.data.allContentfulStore.edges.map(({ node: store }) => {return store}),
      ads: this.props.data.allContentfulAds.edges.map(({ node: ad }) => {return ad}),
      show: false
    }
    this.checkLocalStorageForSavedStore = () => {
      const savedStoreInJSON = window.localStorage.getItem('ReedsMarket-savedStore')
      if (savedStoreInJSON !== null) {
        const savedStore = JSON.parse(savedStoreInJSON)
        let storeAds = this.filterAdsByStores(this.state.ads, savedStore)
        this.setState({
          currentStore: savedStore,
          currentStoreAds: storeAds
        })
      }
    }

    // Geolocation Functions

    this.getLocation = () => {
      // Check if browser supports W3C Geolocation API
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const userCoords = {
              lat: position.coords.latitude,
              lon: position.coords.longitude
            }
            resolve(userCoords)
          }, (err) => {
            console.error(err)
          });
        }
      })
    }

    this.findNearestStore = () => {
      const stores = this.state.stores
      let hash = {};
      this.getLocation().then(coords => {
        const userCoords = new google.maps.LatLng({lat: coords.lat, lng: coords.lon});
        stores.forEach((store) => {
          const addToHashTable = (distance, store) => {
            hash[distance] = []
            hash[distance].push(store)
          }
          addToHashTable(google.maps.geometry.spherical.computeDistanceBetween(userCoords, new google.maps.LatLng({lat: store.coordinates.lat, lng: store.coordinates.lon})), store)
        })
        let minInMeters = Math.min(...Object.keys(hash).map(n => parseFloat(n)))
        let nearestStore = hash[minInMeters][0]
        let storeAds = this.filterAdsByStores(this.state.ads, nearestStore)
        this.setState({
          currentStore: nearestStore,
          currentStoreAds: storeAds
        })
        return nearestStore
      }).then((nearestStore) => {
        this.putStoreInLocalStorage(nearestStore)
      })
    }

    // Geolocation Functions End

    this.putStoreInLocalStorage = (store) => {
      if (this.state.currentStore !== null && this.state.currentStore === store) {
        window.localStorage.setItem('ReedsMarket-savedStore', JSON.stringify(store))
      } else if (this.state.currentStore !== null) {
        window.localStorage.setItem('ReedsMarket-savedStore', JSON.stringify(this.state.currentStore))
      }
    }

    this.storeSelection = (e) => {
      new Promise(resolve => {
        let storeID = e.target.getAttribute('data-storeid')
        let selectedStore = this.state.stores.filter(store => store.id === storeID)[0]
        let storeAds = this.filterAdsByStores(this.state.ads, selectedStore)
        this.setState({
          currentStore: selectedStore,
          currentStoreAds: storeAds
        })
        resolve(selectedStore)
      }).then(selectedStore => {
        this.putStoreInLocalStorage(selectedStore)
      })
    }

    this.filterAdsByStores = (array, currentStore) => {
      const currentStoreID = currentStore.id
      return array.filter(adRecord => {
        return adRecord.storeReferencesForAds.some(store => store.id === currentStoreID)
      })
    }
    this.mapAdGroupAds = (array) => {
      return array.map((ad, index) => (
        <AdsImage data={ad} key={index}/>
      ))
    }
    // Modal Functions
    this.handleToggle = () => {
      if (this.state.show) {
        this.handleHide()
      } else {
        this.handleShow()
      }
    };

    this.handleShow = () => {
      let html = document.getElementsByTagName("html")[0];
      html.style.overflow = "hidden";
      this.setState({ show: true });
    };

    this.handleHide = () => {
      let html = document.getElementsByTagName("html")[0];
      html.style.overflow = "";
      this.setState({ show: false });
    };

    this.handleExit = (e) => {
      this.storeSelection(e)
      this.handleHide()
    };
  }

  componentDidMount(){
    this.checkLocalStorageForSavedStore()
  }


  render() {
    const stores = this.state.stores.map((store, index) => (
      <Location key={index}>
        <h4 onClick={this.storeSelection}>{`${store.storeName}, MS`}</h4>
        <CTAButton variant={"solid-white"} data-storeid={store.id} onClick={this.storeSelection}>Select This Store</CTAButton>
      </Location>
    ))
    const modalLinks = this.state.stores.map((store, index) => (
      <ModalLink key={index} onClick={this.handleExit} data-storeid={store.id}>{store.localName}, MS</ModalLink>
    ))
    if (this.state.currentStore !== null) {
      let AdImages = []
      // This can be potentially N groups as some marketing campagins span stores.
      this.state.currentStoreAds.forEach(AdGroup => {
        AdImages.push(this.mapAdGroupAds(AdGroup.ads))
      })
      return (
        <ExternalWrapper>
          <LocationMasthead>
            <LocationTitle>
              <h2>{`Weekly Ad for ${this.state.currentStore.localName}, MS`}</h2><LocationPinMobile sm={{span: 1}} onClick={this.findNearestStore}><img src={whitePin}/></LocationPinMobile>
            </LocationTitle>
          </LocationMasthead>
          <MobileTabForSelectYourStore onClick={this.handleShow}><div>Select Your Store</div></MobileTabForSelectYourStore>
          <StyledModal
            show={this.state.show}
            onHide={this.handleToggle}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
          >
            <Modal.Header closeButton/>
            <StyledModalBody>
              {modalLinks}
            </StyledModalBody>
          </StyledModal>
          <Container>
            <ContentWrapper>
              <LocationSidebar>
                <LocationHeadline>
                  <LocationPin sm={{span: 1}} onClick={this.findNearestStore}><img src={pin}/></LocationPin>
                  <h4>Select Your Location</h4>
                </LocationHeadline>
                {stores}
              </LocationSidebar>
              <AdsWrapper>
                {AdImages}
              </AdsWrapper>
            </ContentWrapper>
          </Container>
          <ScrollToTop />
        </ExternalWrapper>
      )
    } else {
      return (
        <ExternalWrapper>
          <LocationMasthead>
            <LocationTitle>
              <h2>{`Select a store to view Weekly Ads`}</h2>
            </LocationTitle>
          </LocationMasthead>
          <Container>
            <ContentWrapper>
              <LocationSidebar className="show-location-sidebar-mobile">
                <LocationHeadline>
                  <LocationPin sm={{span: 1}} onClick={this.findNearestStore}><img src={pin}/></LocationPin>
                  <h4>Select Your Location</h4>
                </LocationHeadline>
                {stores}
              </LocationSidebar>
            </ContentWrapper>
          </Container>
        </ExternalWrapper>
      ) 
    }
  }
}
