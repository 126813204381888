import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Ads from '../components/Ads/Ads'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import SEO from '../components/SEO'

const AdsPage = ({ data }) => {
  let localStoreNames = data.allContentfulStore.edges.map(({node: store})=> {return store.localName+', MS'})
  localStoreNames = localStoreNames.slice(0, -1).join('; ')+'; and '+localStoreNames.slice(-1);
  const pageData = {
    title: "Reed's Market",
    metaDescription: `Reed's Market, where you'll find high quality groceries at an affordable price at your local store in ${localStoreNames}.`
  }
  return (
    <Layout>
      <Helmet>
      
      <script async defer src="https://maps.google.com/maps/api/js?key=AIzaSyAj0Hn0Ks2CjCwkSthF_QbFWHOF5vbIQtk&v=3&libraries=geometry"
  type="text/javascript"/>  
        <title>{`Ads - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO pageSEOSpecial={pageData} pagePath="ads" customTitle={"test"} />

      <Ads data={data}/>
    </Layout>
  )
}

export const query = graphql`
query{
  allContentfulStore(sort: {fields: [reedsBrandedStore], order: [DESC]}){
    edges {
      node {
        id
        storeName
        localName
        coordinates {
          lon
          lat
        }
      }
    }
  }
	allContentfulAds {
    edges {
      node {
        storeReferencesForAds {
          id
          storeName
        }
        ads {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
}
`

export default AdsPage
