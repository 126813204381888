import React, {Component} from 'react'
import styled from 'styled-components';
import carrot from '../../../static/utils/carrot.svg';

const ScrollToTopLink = styled.a`
  @media (min-width: 768px) {
    display: inline-flex;
    position: sticky;
    float: right;
    height: 58px;
    width: 558.47px;
    margin-left: auto;
    color: ${props => props.theme.colors.white};
    &:focus, &:hover, &:active {
      color: ${props => props.theme.colors.white};
    }
    background-color: ${props => props.theme.colors.base};
    img {
      margin-left: 36px;
    }
    div {
      font-family: montserrat, sans-serif;
      font-size: 19px;
      font-weight: 700 !important;
      margin: auto 0 auto 25px;
    }
  }
  @media (max-width: 768px) {
    display: none;
    position: fixed;
    right: 10px;
    bottom: 10px;
    float: right;
    height: 50px;
    width: 50px;
    margin-left: auto;
    border-radius: 50%;
    background-image: url('${carrot}');
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(119,116,119,.8);
    img {
      display: none;
    }
    div {
      display: none;
    }
  }
`

let lastScrollY = 0;
let ticking = false;

export default class ScrollToTop extends Component {
  handleScroll = (e) => {
    let el = document.querySelector('#scroll-to-top');
    if (window.innerWidth <= 768 && el !== null) {
      lastScrollY = window.scrollY;
        if (lastScrollY >= 168) {
          if (!ticking) {
            window.requestAnimationFrame(() => {
              el.style.display = "inline-flex";
              ticking = false;
            });
         
            ticking = true;
          }
        } else {
          el.style.display = "none";
        }
    }
  };
  
  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
  }
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  
  render() {
    return (
      <ScrollToTopLink id="scroll-to-top" className={"hide-scroll-to-top"} href={`#`}><img src={carrot}/><div>Scroll to Top</div></ScrollToTopLink>
    )
  }
}